import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nirvana",
  "date": "2020-10-15T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`nirvana, (Sanskrit: “becoming extinguished” or “blowing out”) Pali nibbana, in Indian religious thought, the supreme goal of certain meditation disciplines. Although it occurs in the literatures of a number of ancient Indian traditions, the Sanskrit term nirvana is most commonly associated with Buddhism, in which it is the oldest and most common designation for the goal of the Buddhist path. It is used to refer to the extinction of desire, hatred, and ignorance and, ultimately, of suffering and rebirth. Literally, it means “blowing out” or “becoming extinguished,” as when a flame is blown out or a fire burns out.`}</p>
    <p>{`In his first sermon after his enlightenment, the Buddha (the founder of Buddhism) set forth the Four Noble Truths (one of the core teachings of Buddhism), the third of which was “cessation” (nirodha). This state of the cessation of suffering and its causes is nirvana. The term nirvana has entered Western parlance to refer to a heavenly or blissful state. The European valuation of nirvana as a state of annihilation was the source of the Victorian characterization of Buddhism as a negative and life-denying religion.`}</p>
    <p>{`The Buddha taught that human existence is characterized by various forms of suffering (birth, aging, sickness, and death), which are experienced over the course of many lifetimes in the cycle of rebirth called samsara (literally “wandering”). Seeking a state beyond suffering, he determined that its cause—negative actions and the negative emotions that motivate them—must be destroyed. If these causes could be eradicated, they would have no effect, resulting in the cessation of suffering. This cessation was nirvana. Nirvana was not regarded as a place, therefore, but as a state of absence, notably the absence of suffering. Exactly what persisted in the state of nirvana has been the subject of considerable discussion over the history of the tradition, though it has been described as bliss—unchanging, secure, and unconditioned.`}</p>
    <p>{`Buddhist thinkers have distinguished between “the nirvana with remainder,” a state achieved prior to death, where “the remainder” refers to the mind and body of this final existence, and “the nirvana without remainder,” which is achieved at death when the causes of all future existence have been extinguished and the chain of causation of both physical form and of consciousness have been finally terminated. These states were available to all who followed the Buddhist path to its conclusion. The Buddha himself is said to have realized nirvana when he achieved enlightenment at the age of 35. Although he destroyed the cause of future rebirth, he continued to live for another 45 years. When he died, he entered nirvana, never to be born again.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      